import React, { Component } from "react";
import "./assets/styles/App.css";
import ForgotPassword from "./components/Login/ForgotPassword";
import Loginusername from "./components/Login/Loginusername";
import LoginPassword from "./components/Login/LoginPassword";
import { Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import WelcomeUser from "./components/User/WelcomeUser";
import UserRegistered from "./components/User/UserRegistered";
import Recover from "./components/Login/Recover";
import AdminDashboard from "./components/Dashboard/AdminDashboard";
import EditAdmin from "./components/Dashboard/EditAdmin";
import ResetPassword from "./components/Login/ResetPassword";
import VerifyEmail from "./components/Login/VerifyEmail";
import ReverifyAccount from "./components/Login/ReverifyAccount";
import history from "./utils/history";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" exact={true} component={Loginusername} />
          <Route
            path="/login-password"
            exact={true}
            component={LoginPassword}
          />
          <ProtectedRoute
            path="/edit-admin"
            exact={true}
            component={EditAdmin}
          />
          <Route path="/confirm-account" exact={true} component={WelcomeUser} />
          <Route
            path="/user-registered"
            exact={true}
            component={UserRegistered}
          />
          <ProtectedRoute path="/" exact={true} component={AdminDashboard} />
          <Route
            path="/forgot-password"
            exact={true}
            component={ForgotPassword}
          />
          <Route path="/recover" exact={true} component={Recover} />
          <Route
            path="/reset-password"
            exact={true}
            component={ResetPassword}
          />
          <Route path="/verify-email" exact={true} component={VerifyEmail} />
          <Route
            path="/reverify-account"
            exact={true}
            component={ReverifyAccount}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
