/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { backendBaseUrl } from "../../config";
import { loginPassword, getUser } from "../../constants/endpoints";
import {
  postUnauthenticatedData,
  getAuthenticatedData
} from "../../utils/async";
import { convertEpochToDate, convertEpochToTime } from "../../utils/date";

export default class LoginPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      email: "",
      name: "",
      userId: "",
      isLoaded: true,
      statusCheckSuccessful: false,
      disableButton: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.forgot = this.forgot.bind(this);
  }

  componentDidMount() {
    if (!this.props.location.email) {
      this.props.history.push({
        pathname: "/login"
      });
    }
    const { name, email, userId, lastLoginTime } = this.props.location;
    // console.log("userid: " + userId);

    this.setState({ name, email, userId, lastLoginTime });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  forgot() {
    this.props.history.push({
      pathname: "/forgot-password",
      userId: this.state.userId,
      name: this.state.name,
      email: this.state.email
    });
  }

  handleSubmit() {
    event.preventDefault();
    var data = {
      emailAddress: this.state.email,
      password: this.state.password,
      deviceId: ""
    };
    if (this.state.password != "") {
      this.setState({ disableButton: true });
    }
    postUnauthenticatedData(backendBaseUrl + loginPassword, data)
      .then(result => {
        this.setState({ disableButton: false });
        if (result.status === 200) {
          this.setState({ statusCheckSuccessful: true });
          return result.json();
        } else {
          if (result.status === 401) {
            toast.error("Invalid Password");
          } else if (result.status === 403) {
            this.props.history.push({
              pathname: "/recover",
              email: this.state.email,
              cameFromPasswordPage: true
            });
          } else {
            toast.error("Something went wrong!");
          }
        }
      })
      .then(responseJson => {
        if (this.state.statusCheckSuccessful) {
          window.sessionStorage.setItem("bearer", responseJson.data);
          return Promise.resolve(true);
        }
        return Promise.reject(false);
      })
      .then(() => {
        getAuthenticatedData(backendBaseUrl + getUser).then(result => {
          if (!result?.data?.roles?.includes("USER_ADMIN")) {
            this.props?.history?.push?.("/edit-admin");
          } else {
            this.props?.history?.push?.("/");
          }
        });
      });
  }

  render() {
    let dateText = "";
    if (!this.state.lastLoginTime) {
      dateText = "Never Logged In";
    } else {
      let date = convertEpochToDate(this.state.lastLoginTime);
      let time = convertEpochToTime(this.state.lastLoginTime);
      dateText = `${date} at ${time}`;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.png")}
            />
          </p>

          <p className="center">{this.state.email}</p>
          <FormGroup controlId="email" bsSize="large">
            <FormControl
              autoFocus
              required
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.onChange}
            />
          </FormGroup>
          <div>
            <Button block bsSize="large" type="submit" className="loginbtn">
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                "Next"
              )}
            </Button>

            <span className="forgotspan" onClick={this.forgot}>
              <p className="forgot">Forgot Password?</p>
            </span>
            <Link to="/">
              <p className="back">Back</p>
            </Link>
            <div className="lastLogin">Last Login Activity: {dateText}</div>
          </div>
        </form>
      </div>
    );
  }
}
