import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

export default class UserRegistered extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit() {
    //UVA_API_Call
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this?.state?.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <img
            className="loginlogo"
            src={require("../../assets/images/remidio.png")}
          />
          <p className="center">Congratulations! You Password has been </p>
          <p className="centeruser">updated successfully! </p>
          <Link to="/Login">
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              Exit{" "}
            </Button>
          </Link>
        </form>
      </div>
    );
  }
}
