import { apiToken } from "../config";

export const commonAuthenticatedHeaders = () => {
  return {
    Authorization: "Bearer " + sessionStorage.getItem("bearer"),
    apiToken: apiToken
  };
};
export const commonUnauthenticatedHeaders = () => {
  return { apiToken: apiToken };
};
