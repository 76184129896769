/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import history from "../../utils/history";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdmin: false,
      bearerToken: "",
      firstName: "",
      lastName: "",
      redirect: false
    };
    this.logout = this.logout.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    let promise;
    promise = this.props.results;
    promise.then(result => {
      if (!result?.data?.roles?.includes("USER_ADMIN")) {
        history?.push?.("/edit-admin");
      } else {
        this.setState({ showAdmin: true });
      }
      this.setState({
        firstName: result?.data?.firstName,
        lastName: result?.data?.lastName
      });
    });
  }

  logout() {
    if (sessionStorage.getItem("bearer")) {
      sessionStorage.removeItem("bearer");
    }
    this.setState({ redirect: true });
  }

  render() {
    let adminTemplate = null;
    if (this.state.showAdmin) {
      adminTemplate = (
        <Link to="/">
          <div
            className={
              "adminoption" + (this?.props?.from === "0" ? " selected" : "")
            }
          >
            Users
          </div>
        </Link>
      );
    }
    if (this?.state?.redirect) {
      return <Redirect to={"/login"} />;
    }
    return (
      <div>
        <div className="marginside sidebar-name">
          <div className="adminname">
            {this?.state?.firstName} {this?.state?.lastName}
          </div>
        </div>
        <hr />
        <div className="marginside">
          {adminTemplate}
          <Link to="/edit-admin">
            <div
              className={
                "adminoption" + (this?.props?.from === "1" ? " selected" : "")
              }
            >
              Edit Password
            </div>
          </Link>

          <div onClick={this.logout} className="adminoption logout">
            Logout
          </div>
        </div>
      </div>
    );
  }
}
