/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { backendBaseUrl } from "../../config";
import { loginUsername } from "../../constants/endpoints";
import { getUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";

export default class Loginusername extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dEmail: "",
      Name: "",
      statusCheck: "",
      token: "",
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  onSubmit() {
    if (this.state.dEmail == "") {
      toast.error("Please fill in an email address!");
    } else {
      this.setState({ disableButton: true });
      getUnauthenticatedData(
        backendBaseUrl + loginUsername + "?email=" + this.state.dEmail
      )
        .then(result => {
          if (result.status === 200) {
            return result.json();
          } else {
            this.setState({ disableButton: false });
            if (result.status === 404) {
              return toast.error(
                "User does not exist! Check the email address you entered and try again."
              );
            } else {
              return toast.error(
                "Oops, something went wrong. Please make sure you typed the username correctly!"
              );
            }
          }
        })
        .then(result => {
          let status = result?.data?.accountStatus;
          if (status === "ACTIVE") {
            this.props.history.push({
              pathname: "/login-password",
              name: result.data.firstName,
              email: result.data.email,
              userId: result.data.userId,
              lastLoginTime: result.data.lastLoginTime
            });
          } else if (status === "INACTIVE" || status === "UNVERIFIED") {
            this.props.history.push({
              pathname: "/verify-email",
              email: result.data.email
            });
          } else if (status === "SUSPENDED") {
            this.props.history.push({
              pathname: "/recover",
              email: result.data.email,
              cameFromPasswordPage: false
            });
          }
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.png")}
            />
          </p>
          <p className="center">Sign in to Remidio Account</p>
          <FormGroup controlId="email" bsSize="large">
            <FormControl
              autoFocus
              required
              type="email"
              name="dEmail"
              placeholder="Username"
              onChange={this.onChange}
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            onClick={this.onSubmit}
            type="submit"
            className="loginbtn"
          >
            {this.state.disableButton ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              "Next"
            )}
          </Button>
        </form>
      </div>
    );
  }
}
