/* eslint-disable react/prop-types */
import React from "react";
import "../../assets/styles/App.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { backendBaseUrl } from "../../config";
import { createUser, getSites } from "../../constants/endpoints";
import { getAuthenticatedData, postAuthenticatedData } from "../../utils/async";

class CustomModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      eid: "",
      email: "",
      emailAndDomain: "",
      redirect: false,
      disableButton: true,
      isLoaded: true,
      sites: [{ siteName: "Loading..." }],
      site: 0,
      domain: "",
      roles: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    getAuthenticatedData(backendBaseUrl + getSites).then(
      result => {
        this.setState({
          sites: result?.data,
          site: result?.data[0]?.siteId,
          domain: result?.data[0]?.siteDomain
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }
  onChange(e) {
    if (e.target.name === "site") {
      this.setState({
        [e.target.name]: e.target.value,
        domain: this.state.sites.find(f => {
          return f?.siteId == e?.target?.value;
        }).siteDomain,
        site: parseInt(e.target.value)
      }),
        () => {
          this.setState({
            disableButton:
              this.state.firstname === "" ||
              this.state.lastname === "" ||
              this.state.eid === "" ||
              this.state.email === ""
          });
        };
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value
        },
        () => {
          this.setState({
            disableButton:
              this.state.firstname === "" ||
              this.state.lastname === "" ||
              this.state.eid === "" ||
              this.state.email === ""
          });
        }
      );
    }
  }

  handleChange(e) {
    switch (e.currentTarget.name) {
      case "operator":
        if (!e.currentTarget.checked) {
          this.setState({
            roles: this?.state?.roles?.filter?.(e => e !== "OPERATOR")
          });
        } else {
          this.setState({ roles: [...this.state.roles, "OPERATOR"] });
        }
        break;
      case "infraadmin":
        if (!e.currentTarget.checked) {
          this.setState({
            roles: this.state.roles.filter(e => e !== "INFRA_ADMIN")
          });
        } else {
          this.setState({ roles: [...this.state.roles, "INFRA_ADMIN"] });
        }
        break;
    }
  }
  onSubmit() {
    var data = {
      employeeId: this.state.eid,
      email: this.state.email + "@" + this.state.domain,
      firstName: this.state.firstname,
      lastName: this.state.lastname,
      roles: this.state.roles,
      siteId: this.state.site
    };
    this.setState({ isLoaded: false, disableButton: true });

    postAuthenticatedData(backendBaseUrl + createUser, data).then(result => {
      this.setState({ isLoaded: true, disableButton: false });
      if (result.status === 400) {
        toast.error(
          "The email ID you provided already exists, or the domain you provided is invalid."
        );
      } else if (result.status === 200) {
        toast.success("New user added successfully!");
        this.props.closePopup();
        this.props.resetList();
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  render() {
    return (
      <div className="custommodal">
        <div className="custommodalinner">
          <form className="loginpad" onSubmit={this.handleSubmit}>
            <h6 className="modal-heading">Create Account</h6>
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={this.props.closePopup}
              className="cancle"
              type="submit"
            >
              {" "}
              x{" "}
            </Button>
            <hr className="hrpadding" />

            <FormGroup controlId="name" bsSize="medium">
              <div className="row">
                <div className="col-md-3 formfont">First Name</div>
                <div className="col-md-9">
                  <FormControl
                    autoFocus
                    required
                    type="text"
                    name="firstname"
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <div className="row">
                <div className="col-md-3 formfont">Last Name</div>
                <div className="col-md-9">
                  <FormControl
                    required
                    type="text"
                    name="lastname"
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <div className="row">
                <div className="col-md-3 formfont">Site</div>
                <div className="col-md-9">
                  <select name="site" onChange={this.onChange}>
                    {this.state.sites.map(e => (
                      <option value={e?.siteId} key={e?.siteId}>
                        {e?.siteName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <div className="row">
                <div className="col-md-3 formfont">EID</div>
                <div className="col-md-9">
                  <FormControl
                    required
                    type="text"
                    name="eid"
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <div className="row">
                <div className="col-md-3 formfont">Email ID</div>
                <div className="col-md-6">
                  <FormControl
                    required
                    type="text"
                    name="email"
                    onChange={this.onChange}
                  />
                </div>
                <div className="col-md-3 domain-name">
                  {this.state.domain === "" ? "" : "@"}
                  {this.state.domain}
                </div>
              </div>
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <div className="row formfont">
                <div className="col-md-3 formfont">Roles</div>
                <div className="checkbox">
                  <FormControl
                    className="check"
                    type="checkbox"
                    name="infraadmin"
                    checked={this?.state?.roles?.includes?.("INFRA_ADMIN")}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="checkbox-label formfont">Infra Admin</div>
                <div className="checkbox">
                  <FormControl
                    className="check"
                    type="checkbox"
                    name="operator"
                    checked={this?.state?.roles?.includes?.("OPERATOR")}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="checkbox-label formfont">Operator</div>
              </div>
            </FormGroup>

            <Button
              onClick={this.onSubmit}
              className="loginbtn1 create-account-btn"
              disabled={this.state.disableButton}
            >
              {this.state.isLoaded ? (
                "Create"
              ) : (
                <img src={require("../../assets/images/button-loading.gif")} />
              )}
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default CustomModal;
