import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import AppNavbar from "./AppNavbar";
import Sidebar from "./Sidebar";
import { backendBaseUrl } from "../../config";
import { changePassword, getUser } from "../../constants/endpoints";
import { getAuthenticatedData, putAuthenticatedData } from "../../utils/async";
import { validatePass } from "../../utils/validatePassword";
import { toast } from "react-toastify";

export default class EditAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      reNewPassword: "",
      newPassword: "",
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      containsEightCharacters: false,
      containsBothCases: false,
      containsNumberOrSymbol: false,
      passwordsMatch: true,
      disableButton: true,
      showLoader: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(
      this
    );
    this.handleChangeCurrentPassword = this.handleChangeCurrentPassword.bind(
      this
    );
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
  }

  validatePassword(e) {
    this.setState(validatePass(e, this.state.confirmPassword));
  }
  validateConfirmPassword(e) {
    let confirmPassword = e.target.value;
    let disableButton = !(
      this.state.containsEightCharacters &&
      this.state.containsBothCases &&
      this.state.containsNumberOrSymbol &&
      confirmPassword === this.state.newPassword
    );
    this.setState({
      passwordsMatch: confirmPassword === this.state.newPassword,
      disableButton
    });
  }
  handleChangeCurrentPassword(e) {
    this.setState({ currentPassword: e.target.value });
  }
  handleChangePassword(e) {
    this.validatePassword(e);
    this.setState({ newPassword: e.target.value });
  }
  handleChangeConfirmPassword(e) {
    this.validateConfirmPassword(e);
    this.setState({
      reNewPassword: e.target.value
    });
  }
  onSubmit() {
    this.setState({ disableButton: true, showLoader: true });
    let data = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      reNewPassword: this.state.reNewPassword
    };
    putAuthenticatedData(backendBaseUrl + changePassword, data).then(result => {
      this.setState({ disableButton: false, showLoader: false });
      if (result.status === 200) {
        toast.success("Changed Password Successfully!");
      } else if (result.status === 400) {
        toast.error("You did not enter your current password correctly!");
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
  }

  render() {
    const EightChars = () => {
      if (this.state.containsEightCharacters) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span> contains atleast eight characters
          </p>
        );
      }
    };
    const BothCases = () => {
      if (this.state.containsBothCases) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span> contains both lower(a-z) and
            upper case(A-Z) letters
          </p>
        );
      }
    };
    const SpecialChars = () => {
      if (this.state.containsNumberOrSymbol) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span> contains atleast one number(0-9)
            and a symbol
          </p>
        );
      }
    };
    const Matching = () => {
      if (this.state.passwordsMatch) {
        return null;
      } else {
        return (
          <p className="error">
            <span className="cross"> x </span> Password should match.
          </p>
        );
      }
    };
    return (
      <div>
        <AppNavbar />
        <div className="row">
          <div className="col-md-2 sidebar">
            <Sidebar from="1" results={this.state.userResults} />
          </div>
          <div className="col-md-10">
            <div className="editscreen">
              <h6 className="editheading">Edit Password</h6>
              <FormGroup controlId="password" bsSize="large">
                <FormControl
                  autoFocus
                  required
                  type="password"
                  name="currentPassword"
                  placeholder="Current Password"
                  onChange={this.handleChangeCurrentPassword}
                />
              </FormGroup>
              <FormGroup controlId="password" bsSize="large">
                <FormControl
                  required
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  onChange={this.handleChangePassword}
                />
              </FormGroup>

              <FormGroup controlId="confirmpassword" bsSize="large">
                <FormControl
                  required
                  type="password"
                  name="reNewPassword"
                  placeholder=" Confirm Password"
                  onChange={this.handleChangeConfirmPassword}
                />
              </FormGroup>

              <EightChars></EightChars>
              <BothCases></BothCases>
              <SpecialChars></SpecialChars>
              <Matching></Matching>

              <Button
                block
                bsSize="large"
                onClick={this.onSubmit}
                type="submit"
                className="loginbtn"
                disabled={this.state.disableButton}
              >
                {this.state.showLoader ? (
                  <img
                    src={require("../../assets/images/button-loading.gif")}
                  />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
