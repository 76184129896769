export const validatePass = (e, confirmPassword) => {
  let password = e.target.value;
  let capsCount,
    smallCount,
    numberCount,
    symbolCount,
    containsEightCharacters,
    containsBothCases,
    containsNumberOrSymbol,
    passwordsMatch,
    disableButton;
  capsCount = (password.match(/[A-Z]/g) || []).length;
  smallCount = (password.match(/[a-z]/g) || []).length;
  numberCount = (password.match(/[0-9]/g) || []).length;
  symbolCount = (password.match(/[@!#$%^&]/g) || []).length;
  containsEightCharacters = password.length >= 8;
  containsBothCases = capsCount > 0 && smallCount > 0;
  containsNumberOrSymbol = numberCount > 0 && symbolCount > 0;
  passwordsMatch = password === confirmPassword;
  disableButton = !(
    containsEightCharacters &&
    containsBothCases &&
    containsNumberOrSymbol &&
    passwordsMatch
  );
  return {
    containsEightCharacters,
    containsBothCases,
    containsNumberOrSymbol,
    passwordsMatch,
    disableButton
  };
};
