/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { backendBaseUrl } from "../../config";
import { verifyEmail } from "../../constants/endpoints";
import { postUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
import history from "../../utils/history";

export default class Recover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit() {
    this.setState({ disableButton: true });
    postUnauthenticatedData(
      backendBaseUrl + verifyEmail + "/" + this.props.location.email,
      {}
    ).then(result => {
      this.setState({ disableButton: false });
      if (result.status == 200) {
        toast.success("Check your email to verify your account.");
        history.push("/login");
      } else {
        toast.error("Something Went Wrong");
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.png")}
            />
          </p>
          <p className="centerrecover">
            Your account has not yet been verified.
          </p>
          <div>
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                "Resend Verification Email"
              )}
            </Button>
          </div>
          <Link to="/">
            <p className="backrecover">Back</p>
          </Link>
        </form>
      </div>
    );
  }
}
