/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { backendBaseUrl } from "../../config";
import { forgotPassword } from "../../constants/endpoints";
import { postAuthenticatedData } from "../../utils/async";
import history from "../../utils/history";
import { toast } from "react-toastify";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      name: "",
      disableButton: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { userId } = this.props.location;
    this.setState({ userId, name });
  }

  onSubmit(e) {
    this.setState({ disableButton: true });
    e.preventDefault();
    postAuthenticatedData(
      backendBaseUrl + forgotPassword + "/" + this.props.location.email
    ).then(result => {
      this.setState({ disableButton: false });
      if (result.status === 200) {
        toast.success(
          "A reset email has been sent to your registered email account."
        );
        history.push("/login");
      } else {
        toast.error("Something went wrong!");
      }
      return result.json();
    });
    //UVA_API_CALL
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.png")}
            />
          </p>

          <p className="center">
            Hi, {this.state.name} do you want to send a reset{" "}
          </p>
          <p className="centeruser">
            password link to the registered email id?{" "}
          </p>

          <Button
            block
            bsSize="large"
            onClick={this.onSubmit}
            type="submit"
            className="loginbtn"
          >
            {this.state.disableButton ? (
              <img src={require("../../assets/images/button-loading.gif")} />
            ) : (
              "Send Link"
            )}
          </Button>
          <Link to="/">
            <p className="backrecover">Back</p>
          </Link>
        </form>
      </div>
    );
  }
}
