const getMonth = monthNumber => {
  switch (monthNumber) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
  }
};

export const convertEpochToDate = epoch => {
  if (!epoch) {
    return "Never Logged In";
  }
  let date = new Date(epoch);
  let day = date.getDate();
  let monthNumber = date.getMonth();
  let month = getMonth(monthNumber);
  let year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const convertEpochToTime = epoch => {
  if (!epoch) {
    return "";
  }
  let date = new Date(epoch);
  let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  let hoursTwoDigits = String(hours).padStart(2, "0");
  let minutes = date.getMinutes();
  let minutesTwoDigits = String(minutes).padStart(2, "0");
  let division = date.getHours() > 12 ? "PM" : "AM";
  return `${hoursTwoDigits}:${minutesTwoDigits} ${division}`;
};
