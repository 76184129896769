/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../assets/styles/App.css";
import "../../assets/styles/Login.css";
import { Button } from "react-bootstrap";

import { backendBaseUrl } from "../../config";
import { reverifyAccount } from "../../constants/endpoints";
import { putUnauthenticatedData } from "../../utils/async";
import { toast } from "react-toastify";
import history from "../../utils/history";
import queryString from "query-string";

export default class Recover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableButton: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit() {
    this.setState({ disableButton: true });
    putUnauthenticatedData(
      backendBaseUrl +
        reverifyAccount +
        "?verificationType=ACCOUNT_VERIFICATION",
      {},
      {
        Authorization:
          "Bearer " + queryString.parse(this.props.location.search)?.token
      }
    ).then(result => {
      this.setState({ disableButton: false });
      if (result.status == 200) {
        toast.success("Your account has been reverified.");
        history.push("/login");
      } else {
        toast.error(
          "Something went wrong. Make sure you copied the link in the email correctly."
        );
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <div className="Login">
        <form className="loginpad1" onSubmit={this.handleSubmit}>
          <p className="imgcenter">
            <img
              className="loginlogo"
              src={require("../../assets/images/remidio.png")}
            />
          </p>
          <p className="centerrecover">Your account needs to be reverified.</p>
          <div>
            <Button
              block
              bsSize="large"
              onClick={this.onSubmit}
              type="submit"
              className="loginbtn"
            >
              {this.state.disableButton ? (
                <img src={require("../../assets/images/button-loading.gif")} />
              ) : (
                "Reverify My Account"
              )}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
