import React, { Component } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler
  // NavItem,
  // NavLink
} from "reactstrap";

export default class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.state = { showPopup: false };
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  logout() {
    window.location.href = "/";
  }
  render() {
    return (
      <Navbar color="#63b172" expand="md">
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar></Nav>
        </Collapse>
      </Navbar>
    );
  }
}
