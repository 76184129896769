import React from "react";
import { Table } from "reactstrap";
import "../../assets/styles/App.css";
import Sidebar from "./Sidebar";
import AppNavbar from "./AppNavbar";
import CustomModal from "./CustomModal";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { backendBaseUrl } from "../../config";
import {
  getAllUsers,
  getUser,
  updateUser,
  updateStatus,
  deleteUser,
  getSites
} from "../../constants/endpoints";
import {
  getAuthenticatedData,
  putAuthenticatedData,
  deleteAuthenticatedData
} from "../../utils/async";
import { toast } from "react-toastify";
import { convertEpochToDate } from "../../utils/date";
import { roles } from "../../constants/roles";

export default class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      items: [],
      dName: "",
      dEmail: "",
      firstName: "",
      lastName: "",
      editFirstName: "",
      editLastName: "",
      editEid: "",
      editUserId: "",
      editEmail: "",
      editRoles: "",
      editStatus: "",
      editSite: "",
      editSiteId: 0,
      clickedOnce: false,
      buttonText: "",
      isOpen: false,
      showPopup: false,
      showPopup1: false,
      userResults: getAuthenticatedData(backendBaseUrl + getUser),
      btnMood: "",
      btnFill: false,
      disableColoredBtn: false,
      disableUpdateBtn: false,
      sites: []
    };
    this.toggle = this.toggle.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.togglePopup1 = this.togglePopup1.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleActionButtonClick = this.handleActionButtonClick.bind(this);
    this.resetList = this.resetList.bind(this);
  }

  onChange() {
    this.setState({ showModal: true });
  }

  componentDidMount() {
    getAuthenticatedData(backendBaseUrl + getSites).then(
      result => {
        this.setState({
          sites: result?.data
        });
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
    this.state.userResults.then(
      result => {
        this.setState({ isLoaded: true });
        if (result?.data?.roles?.includes("USER_ADMIN")) {
          this.resetList();
        }
      },
      () => {
        this.setState({ isLoaded: true });
      }
    );
  }

  resetList() {
    getAuthenticatedData(backendBaseUrl + getAllUsers).then(
      result => {
        this.setState({
          isLoaded: true,
          items: result?.data
        });
        this.getDates(result?.data);
      },
      () => {
        this.setState({
          isLoaded: true
        });
      }
    );
  }

  getDates(items) {
    let dates = items?.map?.(item => convertEpochToDate(item.lastLoginTime));
    this.setState({ dates });
  }

  openModal(item) {
    // this.setState({currentSelectedObject: items})
    // eslint-disable-next-line no-console
    let status = "";
    switch (item?.accountStatus) {
      case "ACTIVE":
        status = "Active";
        this.setState({ buttonText: "Block", btnMood: "meh", btnFill: false });
        break;

      case "INACTIVE":
        status = "Inactive";
        break;

      case "UNVERIFIED":
        status = "Unverified";
        this.setState({
          buttonText: "Delete Account",
          btnMood: "warning",
          btnFill: false
        });
        break;

      case "SUSPENDED":
        status = "Suspended";
        this.setState({
          buttonText: "Unblock",
          btnMood: "success",
          btnFill: false
        });
        break;

      default:
        status = "";
        break;
    }
    this.setState({
      showModal: true,
      editFirstName: item?.firstName,
      editLastName: item?.lastName,
      editEid: item?.employeeId,
      editEmail: item?.email,
      editRoles: item?.roles,
      editStatus: status,
      editUserId: item?.userId,
      editSite: this.state.sites.find(e => e.siteId == item?.siteId).siteName,
      editSiteId: item?.siteId
    });
  }
  handleActionButtonClick() {
    if (!this.state.clickedOnce) {
      this.setState({ clickedOnce: true });
      switch (this.state.buttonText) {
        case "Block":
          this.setState({ buttonText: "Confirm Block", btnFill: true });
          break;
        case "Delete Account":
          this.setState({ buttonText: "Confirm Delete", btnFill: true });
          break;
        case "Unblock":
          this.setState({ buttonText: "Confirm Unblock", btnFill: true });
          break;
      }
    } else {
      let data = {};
      this.setState({ disableColoredBtn: true });
      switch (this.state.buttonText) {
        case "Confirm Block":
          data = {
            userId: this.state.editUserId,
            accountStatus: "SUSPENDED"
          };
          putAuthenticatedData(backendBaseUrl + updateStatus, data).then(
            result => {
              this.setState({ disableColoredBtn: false });
              if (result.status === 200) {
                this.closeModal();
                toast.success("User updated successfully");
                this.resetList();
              } else {
                this.closeModal();
                toast.error("Something Went Wrong!");
              }
              return result.json();
            }
          );
          break;
        case "Confirm Delete":
          deleteAuthenticatedData(
            backendBaseUrl + deleteUser + "/" + this.state.editUserId
          ).then(result => {
            this.setState({ disableColoredBtn: false });
            if (result.status === 200) {
              this.closeModal();
              toast.success("User deleted successfully");
              this.resetList();
            } else {
              this.closeModal();
              toast.error("Something Went Wrong!");
            }
          });
          break;
        case "Confirm Unblock":
          data = {
            userId: this.state.editUserId,
            accountStatus: "ACTIVE"
          };
          putAuthenticatedData(backendBaseUrl + updateStatus, data).then(
            result => {
              this.setState({ disableColoredBtn: false });
              if (result.status === 200) {
                this.closeModal();
                toast.success("User updated successfully!");
                this.resetList();
              } else {
                this.closeModal();
                toast.error("Something Went Wrong!");
              }
              return result.json();
            }
          );
          break;
      }
    }
  }
  closeModal() {
    this.setState({ showModal: false, clickedOnce: false });
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  togglePopup1() {
    this.setState({
      showPopup1: !this.state.showPopup1
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onSubmitEdit() {
    const data = {
      employeeId: this.state.editEid,
      userId: this.state.editUserId,
      firstName: this.state.editFirstName,
      lastName: this.state.editLastName,
      roles: this.state.editRoles,
      siteId: this.state.editSiteId
    };
    this.setState({ disableUpdateBtn: true });
    putAuthenticatedData(backendBaseUrl + updateUser, data).then(result => {
      this.setState({ disableUpdateBtn: false });
      if (result.status === 200) {
        this.closeModal();
        toast.success("User updated successfully!");
        getAuthenticatedData(backendBaseUrl + getAllUsers).then(resultI => {
          this.setState({
            isLoaded: true,
            items: resultI?.data
          });
        });
      } else {
        this.closeModal();
        toast.error("Something Went Wrong!");
      }
      return result.json();
    });
  }
  handleChange(e) {
    switch (e.currentTarget.name) {
      case "editfirstname":
        this.setState({ editFirstName: e.currentTarget.value });
        break;
      case "editlastname":
        this.setState({ editLastName: e.currentTarget.value });
        break;
      case "editeid":
        this.setState({ editEid: e.currentTarget.value });
        break;
      case "editinfraadmin":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this?.state?.editRoles?.filter?.(
              e => e !== "INFRA_ADMIN"
            )
          });
        } else {
          this.setState({
            editRoles: [...this.state.editRoles, "INFRA_ADMIN"]
          });
        }
        break;
      case "editoperator":
        if (!e.currentTarget.checked) {
          this.setState({
            editRoles: this.state.editRoles.filter(e => e !== "OPERATOR")
          });
        } else {
          this.setState({ editRoles: [...this.state.editRoles, "OPERATOR"] });
        }
        break;
    }
  }

  render() {
    const { isLoaded, items } = this.state;
    if (!isLoaded) {
      return <div className="loading"></div>;
    } else {
      return (
        <div
          className={
            this.state.showPopup || this.state.showModal
              ? "prevent-scrolling"
              : ""
          }
        >
          <AppNavbar />
          <div className="row">
            <div className="col-md-2 sidebar">
              <Sidebar from="0" results={this.state.userResults} />
            </div>
            <div className="col-md-10 sidepadding">
              <div className="row rowmargin abovetable">
                <div className="addaccount-container">
                  <Button className="addaccount" onClick={this.togglePopup}>
                    Add Account{" "}
                  </Button>
                  {this.state.showPopup ? (
                    <CustomModal
                      text='Click "Close Button" to hide popup'
                      closePopup={this.togglePopup}
                      resetList={this.resetList}
                    />
                  ) : null}
                </div>
              </div>
              <div className="table-container">
                <Table className="tablebox">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Site</th>
                      <th>Email ID</th>
                      <th>EID</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Last Login</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map?.((item, index) => (
                      <tr key={item.userId}>
                        <td id={"dName" + item.userId}>
                          {item.firstName} {item.lastName}
                        </td>
                        <td id={"dName" + item.userId}>
                          {
                            this.state.sites.find(e => e.siteId == item.siteId)
                              ?.siteName
                          }
                        </td>
                        <td id={"dEmail" + item.userId}>{item.email}</td>
                        <td id={"userType" + item.userId}>{item.employeeId}</td>
                        <td id={"id" + item.userId}>
                          {item.roles
                            .map(e => roles[e])
                            .reduce((acc, val) => `${acc} / ${val}`)}
                        </td>
                        <td id={"status" + item.userId}>
                          {item.accountStatus}
                        </td>
                        <td id={"created_at" + item.userId}>
                          {this.state.dates?.[index]}
                        </td>
                        <td className="tablemodal">
                          <a
                            id={item.userId}
                            className="editbtn"
                            onClick={() => this.openModal(item)}
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          {this.state.showModal && (
            <div className="custommodal">
              <div className="custommodalinner">
                <form className="loginpad" onSubmit={this.handleSubmitEdit}>
                  <h6 className="modal-heading">Edit Account</h6>
                  <Button
                    // eslint-disable-next-line react/prop-types
                    onClick={this.closeModal}
                    className="cancle"
                  >
                    {" "}
                    X{" "}
                  </Button>
                  <hr className="hrpadding" />
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">Email ID</div>
                      <div className="col-md-9">
                        <FormControl
                          disabled
                          value={this.state.editEmail}
                          onChange={this.handleChange.bind("editEmail")}
                          type="text"
                          name="lastname"
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="name" bsSize="medium">
                    <div className="row">
                      <div className="col-md-3 formfont">First Name</div>
                      <div className="col-md-9">
                        <FormControl
                          autoFocus
                          type="text"
                          value={this.state.editFirstName}
                          onChange={this.handleChange}
                          name="editfirstname"
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">Last Name</div>
                      <div className="col-md-9">
                        <FormControl
                          type="text"
                          name="editlastname"
                          value={this.state.editLastName}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">Site</div>
                      <div className="col-md-9">
                        <FormControl
                          type="text"
                          name="editsite"
                          disabled
                          value={this.state.editSite}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">EID</div>
                      <div className="col-md-9">
                        <FormControl
                          value={this.state.editEid}
                          onChange={this.handleChange}
                          type="text"
                          name="editeid"
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row formfont">
                      <div className="col-md-3 formfont">Roles</div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="editinfraadmin"
                          checked={this?.state?.editRoles?.includes?.(
                            "INFRA_ADMIN"
                          )}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="checkbox-label formfont">Infra Admin</div>
                      <div className="checkbox">
                        <FormControl
                          className="check"
                          type="checkbox"
                          name="editoperator"
                          checked={this?.state?.editRoles?.includes?.(
                            "OPERATOR"
                          )}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="checkbox-label formfont">Operator</div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      onClick={this.onSubmitEdit}
                      className="loginbtn1 edit-account-btn"
                      disabled={this.state.disableUpdateBtn}
                    >
                      {this.state.disableUpdateBtn ? (
                        <img
                          src={require("../../assets/images/button-loading.gif")}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </FormGroup>
                  <hr className="formmargin" />
                  <FormGroup controlId="email" bsSize="large">
                    <div className="row">
                      <div className="col-md-3 formfont">Status</div>
                      <div className="col-md-9">
                        <b>{this.state.editStatus}</b>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      onClick={this.handleActionButtonClick}
                      disabled={this.state.disableColoredBtn}
                      className={
                        "loginbtn2 edit-account-btn " +
                        this.state.btnMood +
                        (this.state.btnFill ? " fill" : "")
                      }
                    >
                      {this.state.disableColoredBtn ? (
                        <img
                          src={require("../../assets/images/" +
                            this.state.btnMood +
                            "-button-loading.gif")}
                        />
                      ) : (
                        this.state.buttonText
                      )}
                    </Button>
                  </FormGroup>
                </form>
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
